import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import Login from '../views/login/index.vue'
import Directory from '../views/index.vue' 

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Main',
		component: Main,
		directory:false
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/replace',
		name: 'Replace',
		component: ()=>import('@/views/replace.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	{
		path: '/tongji',
		name: 'v',
		component: Directory,
		
		meta: {
			id:1,
			title: '统计',
			allShow:false,
			leven:0,
			parentId:0,
		},
		directory:true
	},
	{
		path: '/home',
		name: 'Home',
		component: ()=>import('@/views/home.vue'),
		meta: {
			id:3,
			title: '余额总览',
			allShow:false,
			leven:1,
			parentId:1,
			islink:true
		},
		directory:true
	},
	// {
	// 	path: '/exchangeRate',
	// 	name: 'exchangeRate',
	// 	component: ()=>import('@/views/exchangeRate.vue'),
	// 	meta: {
	// 		id:3,
	// 		title: '实时汇率',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:1,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	 {
		path: '/payment',
		name: 'Payment',
		component: Directory,
		meta: {
			id:2,
			title: '代付菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/realTimePayment',
		name: 'RealTimePayment',
		component: () => import('@/views/payment/index.vue'),
		meta: {
			id:3,
			title: '实时代付',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:true
	},
	
	/* {
		path: '/bankList',
		name: 'BankList',
		component: () => import('@/views/payment/bankList.vue'),
		meta: {
			id:4,
			title: '银行卡列表',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:false
	}, */
	{
		path: '/transaction',
		name: 'Transaction',
		component: Directory,
		meta: {
			id:5,
			title: '交易菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	// {
	// 	path: '/offlinePay',
	// 	name: 'offlinePay',
	// 	component: () => import('@/views/payment/offlinePay.vue'),
	// 	meta: {
	// 		id:3,
	// 		title: '线下入金',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/offlineOrder',
	// 	name: 'offlineOrder',
	// 	component: () => import('@/views/transaction/offlineOrder.vue'),
	// 	meta: {
	// 		id:17,
	// 		title: '线下入金订单',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:5,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/transactionOrder',
		name: 'TransactionOrder',
		component: () => import('@/views/transaction/index.vue'),
		meta: {
			id:6,
			title: '交易订单',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	{
		path: '/transactionReport',
		name: 'TransactionReport',
		component: () => import('@/views/transaction/report.vue'),
		meta: {
			id:7,
			title: '交易报表',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	
	{
		path: '/traderInfo',
		name: 'TraderInfo',
		component: Directory,
		meta: {
			id:8,
			title: '商户信息菜单',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:false
	},
	{
		path: '/keySet',
		name: 'KeySet',
		component: () => import('@/views/traderInfo/index.vue'),
		meta: {
			id:9,
			title: '密钥设置',
			allShow:false,
			leven:1,
			parentId:8,
			islink:true
		},
		directory:false
	},
	{
		path: '/transactionBillReport',
		name: 'TransactionBillReport',
		component: () => import('@/views/transaction/billReport.vue'),
		meta: {
			id:10,
			title: '余额账变报表',
			allShow:false,
			leven:1,
			parentId:5,
			islink:true
		},
		directory:true
	},
	{
		path: '/childAccountManager',
		name: 'ChildAccountManager',
		component: Directory,
		meta: {
			id:11,
			title: '子账号管理',
			allShow:false,
			leven:0,
			parentId:0,
			islink:false
		},
		directory:true
	},
	{
		path: '/childAccount',
		name: 'ChildAccount',
		component: () => import('@/views/childAccount/index.vue'),
		meta: {
			id:12,
			title: '子账号',
			allShow:false,
			leven:1,
			parentId:11,
			islink:true
		},
		directory:true
	}, /**/
	// {
	// 	path: '/usdtPayment',
	// 	name: 'UsdtPayment',
	// 	component: () => import('@/views/payment/usdtPayment.vue'),
	// 	meta: {
	// 		id:13,
	// 		title: 'USDT代付',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:2,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	
	// {
	// 	path: '/alipayPayment',
	// 	name: 'AlipayPayment',
	// 	component: () => import('@/views/payment/alipay.vue'),
	// 	meta: {
	// 		id:15,
	// 		title: '商户代付',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:2,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	{
		path: '/paymentRecord',
		name: 'PaymentRecord',
		component: () => import('@/views/payment/paymentRecord.vue'),
		meta: {
			id:3,
			title: '代付记录',
			allShow:false,
			leven:1,
			parentId:2,
			islink:true
		},
		directory:true
	},
	{
		path: '/googleVerification',
		name: 'GoogleVerification',
		component: ()=>import('@/views/login/googleVerification.vue'),
		directory:false,
		meta: {
			noNav:true
		},
	},
	// {
	// 	path: '/usdtOrder',
	// 	name: 'UsdtOrder',
	// 	component: () => import('@/views/payment/usdtOrder.vue'),
	// 	meta: {
	// 		id:14,
	// 		title: 'USDT代付记录',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:2,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
	// {
	// 	path: '/taobaoRecord',
	// 	name: 'TaobaoRecord',
	// 	component: () => import('@/views/payment/taobaoRecord.vue'),
	// 	meta: {
	// 		id:16,
	// 		title: '商户代付记录',
	// 		allShow:false,
	// 		leven:1,
	// 		parentId:2,
	// 		islink:true
	// 	},
	// 	directory:true
	// },
]

const router = new VueRouter({
	linkActiveClass: "activeClass",
	/*添加默认样式 activeClass   activeClass 写在组件样式里面   */
	routes
})

export default router
