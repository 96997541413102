import request from '@/util/request'
export default{
	//登录
	login(data){
		return request({url: '/trader/login/doLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/trader/login/logout',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/trader/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/trader/login/bindGoogle',method: 'post',data})		
	},
	//订单状态
	stateList(data){
		return request({url: '/trader/order/stateList',method: 'post',data})		
	},
	//代付订单状态
	outStateList(data){
		return request({url: '/trader/order/outStateList',method: 'post',data})		
	},
	//支付方式
	// $hasRepay = input('hasRepay', 1);//是否查询自动代付（支付类型） 1-是 2-否
	payType(data){
		return request({url: '/trader/order/payType',method: 'post',data})		
	},
	//订单列表
	orderInList(data){
		return request({url: '/trader/order/orderInList',method: 'post',data})		
	},
	//订单列表 统计
	orderInTotal(data){
		return request({url: '/trader/order/orderInTotal',method: 'post',data})		
	},
	
	//交易报表
	orderReport(data){
		return request({url: '/trader/order/report',method: 'post',data})		
	},
	//代付订单
	orderOutList(data){
		return request({url: '/trader/order/orderOutList',method: 'post',data})		
	},
	
	//修改账号密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPassword(data){
		return request({url: 'trader/user/setPassword',method: 'post',data})		
	},
	//修改代付密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPayPassword(data){
		return request({url: 'trader/user/setPayPassword',method: 'post',data})		
	},
	/* 修改公钥
	  $username = input('username');
        $key = input('key');
        $code = input('code');// 谷歌验证码*/
	setSignKey(data){
		return request({url: 'trader/user/setSignKey',method: 'post',data})		
	},
	/* 查询账号信息 
	 $username = input('username');
	 */
	userInfo(data){
		return request({url: 'trader/user/info',method: 'post',data,allowRepetition:true})		
	},
	//实时代付
	createOrder(data){
		return request({url: 'trader/order/create',method: 'post',data,allowRepetition:true})		
	},
	
	//主界面  统计数据
	commonTotal(data){
		return request({url: 'trader/order/commonTotal',method: 'post',data,allowRepetition:true})		
	},
	//余额账变报表
	   /* $size = input('size', Config::get('paginate.list_rows'));
	        $type = input('type');//IN - 入账  OUT - 出账
	        $order_id = input('order_id');//商家订单号 
			startdate":"2021-01-01 00:00:00",
			"enddate":"2021-03-01 23:59:59",
			 "size":"100"
			*/
	moneyChangeList(data){
		return request({url: 'trader/order/moneyChangeList',method: 'post',data,allowRepetition:true})		
	},
	//新增子账号
	addSub(data){
		return request({url: 'trader/user/addSub',method: 'post',data})		
	},
	
	//子账号列表
	subList(data){
		return request({url: 'trader/user/subList',method: 'post',data})		
	},
	//编辑子账号
	editSub(data){
		return request({url: 'trader/user/editSub',method: 'post',data})		
	},
	//重置子账号密码
	setSubPassword(data){
		return request({url: 'trader/user/setSubPassword',method: 'post',data})		
	},
	
	//重置子账号谷歌验证码
	setGoogleKey(data){
		return request({url: 'trader/user/setGoogleKey',method: 'post',data})		
	},
	
	//USDT代付 创建订单
	// public function createUsdt()
	//     {
	//         $username = input('username');
	//         $address = input('address');//币地址
	//         $address_type = input('address_type');//链类型
	//         $money = input('money');//金额
	//         $code = input('code');//谷歌验证
	createUsdt(data){
		return request({url: 'trader/order/createUsdt',method: 'post',data,allowRepetition:true})		
	},
	
	/**
	* USDT代付订单
	public function usdtOrderList()
	{
		$size = input('size', Config::get('paginate.list_rows'));//需要筛选时间段 startdate enddate
		$status = input('status');//状态 1-待处理 2-成功 3-关闭 接口 trader/order/usdtStatus 返回
		$address_type = input('address_type');//接口 trader/order/usdtAddressType 返回
	*/
	usdtOrderList(data){
		return request({url: 'trader/order/usdtOrderList',method: 'post',data,allowRepetition:true})		
	},
	//状态 1-待处理 2-成功 3-关闭 
	usdtStatus(data){
		return request({url: 'trader/order/usdtStatus',method: 'post',data,allowRepetition:true})		
	},
	//USDT链接状态
	usdtAddressType(data){
		return request({url: 'trader/order/usdtAddressType',method: 'post',data,allowRepetition:true})		
	},
	//淘宝实时代付
	createNew(data){
		return request({url: 'trader/order/createNew',method: 'post',data,allowRepetition:true})		
	},
	// 线下入金订单列表.
	offlineOrder(data){
		return request({url: 'trader/order_offline/allList',method: 'post',data,allowRepetition:true})		
	},
	// 所有币种列表.
	getCurrencys(data){
		return request({url: 'trader/order_offline/currency',method: 'post',data,allowRepetition:true})		
	},
	// 所有币种列表.
	offlinePay(data){
		return request({url: 'trader/order_offline/addOrder',method: 'post',data,allowRepetition:true})		
	},

	// 汇率
	exchangeRate(data){
		return request({url: 'trader/info/getCurrencyExchangeRate',method: 'post',data,allowRepetition:true})	
	},
	userMoney(data){
		return request({url: '/trader/user/userMoney',method: 'post',data,allowRepetition:true})	
	},
	userMentionMoney(data){
		return request({url: '/trader/order/create',method: 'post',data})		
	},
	// 下载多笔上传文档模板
	downLoadFile(data){
		return request({url: '/trader/order/downLoadFile',method: 'post',data})		
	},
	// 上传多笔文件代付
	createXlsPaymentOrder(data){
		return request({url: '/trader/order/createXlsPaymentOrder',method: 'post',data})		
	},
	// 查询代付回单
	checkRepayImg(data){
		return request({url: '/trader/order/checkRepayImg',method: 'post',data})		
	},
	// /trader/order/repayType  商户后台可用的代付方式
	repayType(data){
		return request({url: '/trader/order/repayType',method: 'post',data})		
	},
}