<template>
	<div class="login-container">
		<div class="backBox">
			<div class="conDiv">				
				<div class="loginBox">
					<div class="loginContent mb40">
						<div class="mr20">
							<div class="logo" v-if="$PayNameImg == 'laike'">
								<img class='logoImg' src="../../assets/image/logo.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'tianrong'">
								<img class='logoImg' src="../../assets/image/tr.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'jindong'">
								<img class='logoImg' src="../../assets/image/jd.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'SLONG'">
								<img class='logoImg' src="../../assets/image/SLONG.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'bitong'">
								<img class='logoImg' src="../../assets/image/bitong.jpg" />
							</div>
							<div class="logo" v-else-if="$PayNameImg == 'aipay'">
								<img class='logoImg' src="../../assets/image/aipay.jpg" />
							</div>
							<div class="logo" v-else>
								<img class='logoImg' src="../../assets/image/commonLogo.jpg" />
							</div>
						</div>
						<div class="guli">
							<span>商户后台管理</span>
						</div>
					</div>
					
					<!-- <div class="loginTitle">登 录</div> -->
					<span><el-input autocomplete="new-password" class="loginInput" prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="username" clearable></el-input></span>
					<span><el-input autocomplete="new-password" class="mt40 loginInput" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="password" show-password></el-input></span>
					<span><el-input class="mt40" prefix-icon="el-icon-key" placeholder="请输入谷歌验证吗" v-model="code" clearable  v-if="!googleCode"></el-input></span>
					<span><el-button @click="login()" class="mt40" type="primary" style="width: 100%;" :disabled="loading"  v-if="!googleCode">登 录</el-button></span>
					<!-- <span><el-button class="mt40" type="primary" style="width: 100%;" @click="validateGcode()">谷歌验证</el-button></span>
					<span><el-button class="mt40" type="warning" style="width: 100%;" @click="validateGcode()"v-if="googleCode">下一步</el-button></span> -->
					<span><el-button class="mt40" type="primary" style="width: 100%;" @click="toGoogleCode()" v-if="!googleCode">谷歌绑定</el-button></span>
					<span><el-button class="mt40" type="primary" style="width: 100%;" @click="validateGcode()"v-if="googleCode">下一步</el-button></span>
					
				</div>				
			</div>
		</div>
		<!-- <el-dialog class="dialog" top="200px" title="验证码" :visible.sync="dialogVisible" width="400px">
			<div class="qrcode">
				<img :src="qrcode" alt="">
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '../../util/local.js'
	export default {
		name: 'Login',
		data() {
			return {
				username:'',
				password:'',
				loading:false,
				code:'',
				dialogVisible: false,
				qrcode:'',
				googleCode:false,
			}
		},
		created() {
			
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.login()
				  }
				
			  }
			}
		},
		watch: {
			// $route: {
			//   handler: function(route) {
			//     this.redirect = route.query && route.query.redirect
			//   },
			//   immediate: true
			// }
		},
		methods: {
			
			login(){
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				if(isEmpty(this.code,'请输入谷歌验证码')){
					return
				}
				console.log('ddd')
				let data={
					username:this.username,
					password:this.$md5(this.password),
					code:this.code,
				}
				console.log(this.username,this.$md5(this.password))
				
				this.$api.login(data).then(res => {
					this.loading = false
					if(res.status === 1){
						let data = res.data
						let is_main = data.is_main==1?true:false
						let status_key = data.status_key==0?true:false
						let status_pay = data.status_pay==0?true:false
						this.$local.set('shusername',data.username);
						this.$local.set('shtid',data.tid);
						//子账号相关权限
						this.$local.set('is_main',is_main);
						this.$local.set('status_key',status_key);
						this.$local.set('status_pay',status_pay);
						this.$router.push('/home')
						
						this.$router.options.routes.forEach(item=>{
							if (item.path =='/childAccountManager') {
								item.directory = is_main
							}
							if (item.path =='/realTimePayment') {
								item.directory = status_pay
							}
						})
						this.$parent.getNav()
					}else{
						this.$message({
						  type: 'error',
						  message: res.msg
						}); 
					}
					console.log(res)
				}).catch(error=>{this.loading = false})
			},
			close(){
				
			},
			toGoogleCode(){
				this.googleCode=true;
			},
			validateGcode(){
				
				if(isEmpty(this.username,"请输入账号")){
					return
				}
				if(isEmpty(this.password,"请输入密码")){
					return
				}
				let data={
					username:this.username,
					password:this.$md5(this.password)
				}
				
				this.$api.validateGcode(data).then(res=>{
					//1 成功 未绑定 3已绑定   2未登录  其他失败
					
					if(res.status === 1){
						local.set('googleInfo',res.data)
						this.$router.push({path:"/googleVerification"})
					}else if(res.status == 3){
						this.googleCode = false
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}else{
						this.$message({
						  type: 'info',
						  message: res.msg
						});    
					}
				})
			},
		
		}
	}
</script>
<style>
	.loginInput .el-input__inner{
		/* background: transparent;
		color: #000000;
		border: none;
		border-bottom: solid 1px #ddd;
		border-radius: 3px; */
	}
</style>
<style lang="scss" scoped>
	.login-container {
		height: 100vh;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// background: url(../../assets/image/bgshanghu.jpg) no-repeat; // ccpay
		background: url(../../assets/image/loginbg.svg) no-repeat; // 晟隆
		background-size: cover;
		.backBox{
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			// background-color: rgba(0,0,0,0.3);
		}
		.loginTitle{
			font-size: 20px;
			text-align: center;
			margin-bottom: 40px;
			color: #fff;
		}
		.loginBox{
			border-radius: 3px;
			background-color: rgba(255,255,255,1);
			padding: 40px 20px;
			width: 450px;
			// padding-bottom: 100px;
			box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
			
			.logo{
				.logoImg{
					height: 77px;
					width: 77px;
					text-align: center;
					border-radius: 18px;
				}
			}
		}
		
	}
	.qrcode{
	    display: inline-block;
		width: 100%;
		height: 100%;
	    img {
			width: 100%;
	        background-color: #fff; //设置白色背景色
	        box-sizing: border-box;
	    }
	}
	.dialog{
		// transform: translate(0,25%);
	}
	
	.guli {
	  display: flex;            /* 启用 Flexbox 布局 */
	  justify-content: center;  /* 水平方向居中 */
	  align-items: center;      /* 垂直方向居中 */
	  span {
	    font-size: 1.625rem;          /* 根据需要调整文字大小 */
	  }
	}
	
	.loginContent{
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
